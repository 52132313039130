export const parseJson = jsonString => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        return null;
    }
};

export const appendToArrayWithSpread = (baseArray, appendingArray) => {
    try {
        return [...baseArray, ...appendingArray];
    } catch (error) {
        return baseArray;
    }
};

export const stringifyJson = jsonObject => {
    try {
        return JSON.stringify(jsonObject);
    } catch (error) {
        return null;
    }
};

export const getFilterGraphParameters = (param, val, removingArray) => {
    if (val !== null) {
        return { add: [{ param, val }], remove: removingArray };
    }
    return { add: null, remove: removingArray };
};

export const syscoBrandValues = {
    true: '1',
    false: null
};