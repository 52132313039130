import React from 'react';
import PropTypes from 'prop-types';
import AppDrawer from 'components/AppDrawer';
// import RecentActivityView from 'components/RecentActivityView';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import applicationsSelector from 'selectors';
import _ from 'lodash';

function Homepage({ filteredApps, userId, message, color, align, applications }) {
  return (
    <React.Fragment>
      <AppDrawer filteredApps={filteredApps} userId={userId} applications={applications} />
      {!_.isEmpty(message) && <div className={`prod-banner ${color ? color : ''} ${align ? align : ''}`}>{ReactHtmlParser(message)}</div>}
      {/* <RecentActivityView /> */}
    </React.Fragment>
  );
}

Homepage.propTypes = {
  filteredApps: PropTypes.array
};
function mapStateToProps(state) {
  return {
    filteredApps: applicationsSelector(state.user.data),
    message: state.scorecards.banner.message,
    color: state.scorecards.banner.color,
    applications: state.user.data.applications,
    align: state.scorecards.banner.align
  };
}

export default connect(mapStateToProps)(Homepage);
