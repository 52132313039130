import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import ScoreCard from './ScoreCard';
import ExtendedSummary from './ExtendedSummary';
import * as batchesActions from 'actions/actions';

function DashboardSummary({ scorecards, suvc, graphFilter,userApplications, actions }) {

    useEffect(() => {
        if (suvc != null) {
            actions.loadScores({ suvc: suvc });
        }
    }, [actions, suvc]);

    return (
        <div className="score-cards" style={{ display: scorecards.scoresLoading ? 'none' : 'block' }}>
            <div className="cards">
                {scorecards.scores.map(scorecard => (
                    <ScoreCard
                        key={scorecard.title}
                        actions={actions}
                        cardType={scorecard.cardType}
                        title={scorecard.title}
                        score={scorecard.score}
                        loading={scorecards.scoresLoading}
                        activeChart={scorecards.activeChart}
                        summaryItems={scorecards.summaryItems}
                        suvc={suvc}
                        graphFilter={graphFilter}
                        filterForGraphs={scorecards.filterForGraphs}
                    />
                ))}
            </div>
            <div className="charts">
                {scorecards.activeChart && scorecards.activeChart.length > 0 && (
                    <ExtendedSummary
                        summaryItems={scorecards.summaryItems}
                        actions={actions}
                        suvc={suvc}
                        graphFilter={graphFilter}
                        filterForGraphs={scorecards.filterForGraphs}
                        taxonomy={scorecards.taxonomy}
                        brands={scorecards.brands}
                        activeChart={scorecards.activeChart}
                        userApplications={userApplications}
                    />
                )}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        scorecards: state.scorecards,
        graphFilter: state.scorecards.graphfilter,
        userApplications: state.user.data.applications
    };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSummary));