import React from 'react';

const resetAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar';
  }
};

function AppBar() {
  resetAppHeaderToAppMode();
  return <React.Fragment></React.Fragment>;
}

export default AppBar;
