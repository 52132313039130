import { action } from 'reduxHelpers';
import {
    USER,
    SCORES,
    CHARTS,
    SUMMARY,
    FILTERFORGRAPH,
    BANNERMESSAGE
} from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId }); // test

const loadScores = suvcObj => action(SCORES.REQUEST, suvcObj);
const activateCharts = chartType => action(CHARTS.REQUEST, chartType);
const getDashboardSummary = suvcObj => action(SUMMARY.REQUEST, suvcObj);
const changeFilterForGraph = filter => action(FILTERFORGRAPH.REQUEST, filter);
const getGeneralBannerMessage = () => action(BANNERMESSAGE.REQUEST, {});

export {
    loadUserDetails,
    loadScores,
    activateCharts,
    getDashboardSummary,
    changeFilterForGraph,
    getGeneralBannerMessage
};
