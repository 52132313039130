var refs = 0;
var dispose;
var content = require("!!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-2-1!../../node_modules/postcss-loader/src/index.js??postcss!../../node_modules/sass-loader/lib/loader.js??ref--6-oneOf-2-3!./App.scss");
var options = {"injectType":"lazyStyleTag"};

options.insert = "head";
options.singleton = false;

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

if (content.locals) {
  exports.locals = content.locals;
}

exports.use = function() {
  if (!(refs++)) {
    dispose = require("!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);
  }

 return exports;
};

exports.unuse = function() {
  if (refs > 0 && !--refs) {
    dispose();
    dispose = null;
  }
};

