import { combineReducers } from 'redux';
import { createReducer } from 'reduxHelpers';
import initialState from 'store/initialState';
import {
    USER,
    SCORES,
    CHARTS,
    SUMMARY,
    FILTERFORGRAPH,
    BANNERMESSAGE
} from 'actions/actionTypes';
import _ from 'lodash';

const user = createReducer(USER, initialState.user);

const scorecards = (state = initialState.scorecards, action) => {
    switch (action.type) {
        case SCORES.SUCCESS:
            return {
                ...state,
                scores: mergeScores(initialState.scorecards.scores, action.payload),
                scoresLoading: false,
                ...getTaxonomyAndBrands(action.payload)
            };
        case CHARTS.REQUEST:
            return { ...state, activeChart: action.chartType };
        case SUMMARY.REQUEST:
            return { ...state, summaryItemsLoading: true }
        case SUMMARY.SUCCESS:
            return { ...state, summaryItems: action.payload.summaryItems, graphfilter: action.payload.graphfilter, summaryItemsLoading: false };
        case FILTERFORGRAPH.REQUEST:
            return { ...state, filterForGraphs: action.filter };
        case BANNERMESSAGE.SUCCESS:
            return { ...state, banner: action.payload };
        default:
            return { ...state };
    }
};

const getOverallScore = (scores) => {
    let total = 0;
    scores.forEach((scoreObj) => {
        total += scoreObj.score;
    })

    return {
        cardType: 'overall',
        title: 'OVERALL SCORE',
        score: Math.round((total / scores.length) * 10) / 10
    }
}

const mergeScores = (scores, payload) => {
    let mergedScores = [];
    scores.forEach(scoreElement => {
        let payloadScore = _.find(payload, payloadElement => {
            return payloadElement.cardType === scoreElement.cardType;
        });
        if (payloadScore) {
            mergedScores.push({ ...scoreElement, score: payloadScore.score });
        }
    });

    mergedScores.unshift(getOverallScore(mergedScores));

    return mergedScores;
};

const getTaxonomyAndBrands = payload => {
    let payloadScore = _.find(payload, payloadElement => {
        return payloadElement.cardType === 'attribution';
    });
    if (payloadScore) {
        return { taxonomy: { ...payloadScore.taxonomy }, brands: [...payloadScore.brands] };
    } else {
        return {};
    }
};

export default combineReducers({ user, scorecards });
