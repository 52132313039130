import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import HomePage from 'pages/HomePage';
import * as userActions from 'actions/actions';
import AppBar from 'components/AppBar';
import styles from './styles/App.scss';

function MainApp({ actions, userId }) {
  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    actions.loadUserDetails(userId);
    actions.getGeneralBannerMessage();
  }, [userId, actions.loadUserDetails, actions]);

  return (
    <div className="wrapper dashboard-wrapper">
      <AppBar />
      <HomePage userId={userId} />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(
  null,
  mapDispatchToProps
)(MainApp);
