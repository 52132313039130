import axios from 'axios';
import { getIdToken } from '../Authenticator';

const baseURL = process.env.REACT_APP_BFF;

async function getHeaders() {
  const token = (await getIdToken()).jwtToken;
  return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
}

function postRequest(url, data) {
  return axios.post(`${baseURL}${url}`, data, {
    headers: getHeaders()
  });
}

async function getRequest(url, params = {}) {
  // '/dev/qrf/user/' + userId
  return axios.get(`${baseURL}${url}`, {
    headers: await getHeaders(),
    params
  });
}

function putRequest(url, data) {
  return axios.put(`${baseURL}${url}`, data, {
    headers: getHeaders()
  });
}

function deleteRequest(url) {
  return axios.delete(`${baseURL}${url}`, {
    headers: getHeaders()
  });
}

async function getBannerMessage() {
  const url = baseURL.replace('dashboard', 'banner/message');
  const params = {};
  const headers = await getHeaders();

  return axios.get(`${url}`, {
    headers: headers,
    params
  });
}

export { postRequest, getRequest, putRequest, deleteRequest, getHeaders, getBannerMessage };
