let authenticator = null;

export const setAuth = auth => {
  authenticator = auth;
};

export const getAuth = () => {
  return authenticator;
};

export const getIdToken = async () => {
  const currentUser = authenticator && (await authenticator.currentAuthenticatedUser());
  return currentUser.signInUserSession.idToken;
};

export const getCurrentUserRole = async () => {
  const token = await getIdToken();
  const groups = token && token.payload['cognito:groups'];
  const role = groups ? groups[0] : 'default';
  return role;
};
