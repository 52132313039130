import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getRequest, getBannerMessage } from '_http';
import { action } from 'reduxHelpers';
import openNotification from 'components/Notification';
import { USER, SCORES, SUMMARY, BANNERMESSAGE } from 'actions/actionTypes';
import _, { includes, split } from 'lodash';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getUserId = username => {
  if (includes(username, adPrefix)) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  var oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

function* loadUserAsync({ userId }) {
  try {
    const response = yield getRequest(`/user/${getUserId(userId)}`);
    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', { description: error.message, className: 'error', message: 'USER LOADING ERROR' })
    );
  }
}

function* loadScoresAsync({ suvc }) {
  try {
    let response = yield getRequest(`/user/scores/${suvc}`);
    let scores = [];
    if (response.data.scores) {
      scores = response.data.scores;
    }
    yield put({ type: SCORES.SUCCESS, payload: scores });
  } catch (error) {
    yield put({ type: SCORES.FAILURE, payload: error.message });
  }
}

function* getDashboardSummaryAsync({ suvc, graphfilter }) {
  try {
    const uri = graphfilter ? `?graphfilter=${encodeURIComponent(graphfilter)}` : `?graphfilter=[]`;
    const response = yield getRequest(`/summary/${suvc}${uri}`);
    let summaryItems = {};

    if (response.data) {
      summaryItems = response.data
    }

    yield put({ type: SUMMARY.SUCCESS, payload: { summaryItems, graphfilter } });
  } catch (error) {
    yield put({ type: SUMMARY.FAILURE, payload: error.message });
  }
}

function* getGeneralBannerMessageAsync() {
  try {
    const response = yield getBannerMessage();

    yield put({
      type: BANNERMESSAGE.SUCCESS,
      payload: {
        align: _.get(response, 'data.align', "center"),
        message: _.get(response, 'data.message', ""),
        color: _.get(response, 'data.color', null)
      }
    });
  } catch (error) {
    yield put({ type: BANNERMESSAGE.FAILURE });
  }
}

function* watchLoadUser() {
  yield takeLatest(USER.REQUEST, loadUserAsync);
}

function* showNotificationAsync(notificationAction) {
  const { message, description, className } = notificationAction;
  yield openNotification({ message, description, className });
}

function* watchShowNotification() {
  yield takeEvery('SHOW_NOTIFICATION', showNotificationAsync);
}

function* watchLoadScores() {
  yield takeLatest(SCORES.REQUEST, loadScoresAsync);
}

function* watchGetDashboardSummaryAsync() {
  yield takeLatest(SUMMARY.REQUEST, getDashboardSummaryAsync);
}

function* watchGetGeneralBannerMessageAsync() {
  yield takeLatest(BANNERMESSAGE.REQUEST, getGeneralBannerMessageAsync);
}

export default function* rootSaga() {
  yield all([
    watchLoadUser(),
    watchShowNotification(),
    watchLoadScores(),
    watchGetDashboardSummaryAsync(),
    watchGetGeneralBannerMessageAsync()]);
}
