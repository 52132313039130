import React from 'react'
import { Chart } from 'react-google-charts';

function BrandComplianceChart({ brandCompliance, noEnrichment, withEnrichment }) {

    const getFormattedData = () => {
        let data = [['', 'Sysco Brand Compliance', { role: 'annotation' }, 'National Brand Compliance', { role: 'annotation' }]];
        let syscoTotal = noEnrichment.sysco + withEnrichment.sysco;
        let nationalTotal = noEnrichment.national + withEnrichment.national;

        brandCompliance.forEach((obj) => {
            let arr = [];
            let sysco = Math.round(((obj.sysco / syscoTotal) * 100) * 10) / 10;     // Calculate percentage and round off to 1 decimal place
            let national = Math.round(((obj.national / nationalTotal) * 100) * 10) / 10;
            let syscoAnnotation = `${sysco}%`;
            let nationalAnnotation = `${national}%`;

            arr.push(obj.name)
            arr.push(sysco)
            arr.push(syscoAnnotation)
            arr.push(national)
            arr.push(nationalAnnotation)

            data.push(arr)
        })

        return data;
    }

    return (
        <div className="brand-compl-chart-wrapper" style={{ width: '100%' }}>
            <div className="brand-compl-chart-title">Brand Compliance (%)</div>
            <div className="chart-legend">
                <div className="color-box" />
                <div className="legend-name">Sysco Brand Compliance (%)</div>
                <div className="color-box" />
                <div className="legend-name">National Brand Compliance (%)</div>
            </div>
            <Chart
                width={'100%'}
                height={'320px'}
                chartType="ColumnChart"
                loader={<div style={{ 'textAlign': 'center', 'marginTop': '130px' }}>Loading Chart...</div>}
                data={getFormattedData()}
                options={{
                    colors: ['#F29392', '#8DCB86'],
                    legend: { position: 'none' },
                    tooltip: {
                        trigger: 'none'
                    },
                    annotations: {
                        alwaysOutside: true, stemColor: 'none', textStyle: { color: '#505457', auraColor: 'none' }
                    },
                    hAxis: {
                        textStyle: {
                            color: '#6a737b'
                        }
                    },
                    vAxis: {
                        textStyle: {
                            color: '#6a737b'
                        }
                    }
                }}
            />
        </div>
    )
}

export default BrandComplianceChart