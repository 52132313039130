import React from 'react';
import _ from 'lodash';

class ScoreCard extends React.Component {

  render() {
    let { cardType, score, title, loading, actions, activeChart, summaryItems, suvc } = this.props;

    let cardColor = 'red';
    if (score >= 50) {
      cardColor = 'yellow';
    }
    if (score >= 80) {
      cardColor = 'green';
    }
    if (loading) {
      cardColor = 'gray';
    }

    let inactive = '';

    if (activeChart !== '' && activeChart !== cardType) {
      inactive = 'inactive';
    }

    const activateCharts = () => {
      actions.activateCharts({ chartType: activeChart === cardType ? '' : cardType });

      if (cardType && _.isEmpty(summaryItems)) {
        actions.getDashboardSummary({ suvc: suvc, graphfilter: null });
        actions.changeFilterForGraph({ filter: { brand: null, bc: null, ig: null, ag: null, sysco: null } });
      }
    };

    let overallType = cardType === 'overall' ? 'overall' : '';

    return (
      <div className={`score-card ${cardColor} ${inactive} ${overallType}`} id="attribution-card" onClick={activateCharts}>
        <div className="card-details">
          <div className="card-title">{title}</div>
          <div className="card-score">{score + '%'}</div>
        </div>
        <div className="background-graph"></div>
      </div>
    );
  }
}

export default ScoreCard;
