import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const isAdhereToSearchCriteria = (element, searchString) => {
  const search = searchString.trim();
  if (search === '') {
    return true;
  }
  const regex = new RegExp(search, 'gi');
  const matched = element.title.match(regex) || element.titleInfo.match(regex);
  return matched;
};

export function ApplicationsGrid({ shouldShowInfo, filteredApps, searchString, t, userId }) {
  console.log("user id : ", userId);

  const mobileInlineStyle = (mobileStyle, webStyle) => {
    webStyle = webStyle || {};
    return isMobile ? mobileStyle : webStyle;
  };

  return (
    <ul className={ !isMobile ? "apps dashboard-apps" : "mobile-app-tiles"}  style={mobileInlineStyle({height: `${(window.innerHeight - 85)}px`})}>
      {filteredApps
        .filter(element => isAdhereToSearchCriteria(element, searchString))
        .map(element => {
          if (element.newWindow) {
            let url = element.url
            element.urlrules.forEach((rule) =>{
              if(userId.match(rule.regex)){
                url = rule.url
              }
            })
            console.log("urlrules Prefix : ", url);
            return (
              <a
                key={`tile-wrapper${element.class}`}
                href={url}
                target="_blank"
                style={{ textDecoration: 'none' }}
                rel="noopener noreferrer"
              >
                <li className="tile-wrapper">
                  <div key={`tile ${element.class}`} className={classNames('tile hover-brighten', element.class)}>
                    <div key={`icon ${element.class}`} className="icon">
                      <span className={element.icon} />
                    </div>
                    <div key={`divider ${element.class}`} className="divider" />
                    <div key={`title ${element.class}`} className="title">
                      {t(element.title)}
                    </div>
                  </div>
                  {shouldShowInfo && (
                    <div key={`tile-info ${element.class}`} className="tile-info">
                      {t(element.titleInfo)}
                    </div>
                  )}
                </li>
              </a>
            );
          } else {
            return (
              <Link key={`tile-wrapper${element.class}`} to={element.url} style={{ textDecoration: 'none' }}>
                <li className="tile-wrapper">
                  <div key={`tile ${element.class}`} className={classNames('tile hover-brighten', element.class)}>
                    <div key={`icon ${element.class}`} className="icon">
                      { element.emoji && <span className='emoji-icon' >{element.emoji}</span> }
                      { element.image && <span className={classNames('image-icon', {'image-small-icon': element.smallIcon})} style={{backgroundImage: `url(${element.image})`}} /> }
                      { !element.emoji && !element.image && <span className={element.icon} /> }
                    </div>
                    <div key={`divider ${element.class}`} className="divider" />
                    <div key={`title ${element.class}`} className="title">
                      {t(element.title)}
                    </div>
                  </div>
                  {shouldShowInfo && (
                    <div key={`tile-info ${element.class}`} className="tile-info">
                      {t(element.titleInfo)}
                    </div>
                  )}
                </li>
              </Link>
            );
          }
        })}
    </ul>
  );
}

ApplicationsGrid.propTypes = {
  shouldShowInfo: PropTypes.bool,
  filteredApps: PropTypes.array,
  searchString: PropTypes.string,
  t: PropTypes.func
};

export default withTranslation()(ApplicationsGrid);
