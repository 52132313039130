import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Select } from 'antd';
import * as _ from 'lodash';
import {
  parseJson,
  appendToArrayWithSpread,
  stringifyJson,
  getFilterGraphParameters,
  syscoBrandValues
} from '../util/helpers';

import BrandComplianceChart from './BrandComplianceChart';

class ExtendedSummary extends React.Component {

  summaryCards = ({ totalItems, completeness, noEnrichment, withEnrichment }) => {

    return (
      <div className="summary-cards-list">
        <div className="summary-card">
          <div>
            <div className="summary-card-title">Total Items</div>
            <div className="sc-score">{totalItems.count}</div>
            <div>{`Last Updated: ${new Date(totalItems.lastUpdated).toLocaleDateString()}`}</div>
          </div>
        </div>

        <div className="summary-card">
          <div>
            <div className="summary-card-title">Completeness %</div>
            <div className="sc-score">{`${completeness}%`}</div>
            <div>{completeness === 100 ? 'Compliant' : 'Not Compliant'}</div>
          </div>
        </div>

        <div className="summary-card">
          <div>
            <div className="summary-card-title">Incomplete Enrichment</div>
            <div>
              <div className="menuicon fi flaticon-caret-down" />
              <div className="sc-score sc-enrich">{noEnrichment.sysco + noEnrichment.national}</div>
            </div>
            <div className="sc-enrich-wrap">
              <div className="sysco-nat-left">
                <div>Sysco</div>
                <div>{noEnrichment.sysco}</div>
              </div>
              <div className="sysco-nat-right">
                <div>National</div>
                <div>{noEnrichment.national}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="summary-card">
          <div>
            <div className="summary-card-title">Complete Enrichment</div>
            <div>
              <div className="menuicon fi flaticon-caret-down caret-up " />
              <div className="sc-score sc-enrich">{withEnrichment.sysco + withEnrichment.national}</div>
            </div>
            <div className="sc-enrich-wrap">
              <div className="sysco-nat-left">
                <div>Sysco</div>
                <div>{withEnrichment.sysco}</div>
              </div>
              <div className="sysco-nat-right">
                <div>National</div>
                <div>{withEnrichment.national}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRedirectMessage = (userApplications) => {
    const activeChart = this.props.activeChart;
    let additionalMessage = '';
    let message = <div />

    if (activeChart === 'attribution') {
      additionalMessage = ' & Product Attribution charts.';
    } else if (activeChart === 'image') {
      additionalMessage = ' & Product Images charts.';
    } else if (activeChart === 'fnb') {
      additionalMessage = ' & Features & Benefits charts.';
    }


    const itemAPP = _.includes(userApplications, 'ITMAPP');
    console.log('itemAPP ', itemAPP);

    if (_.includes(userApplications, 'ITMAPP')) {
      message = (
        <div className="redirect-msg">
          Please go to <Link to={'item-management'}>Item Management</Link> to view Item Level Scores
          {`${!_.isEmpty(additionalMessage) ? additionalMessage : '.'}`}
        </div>
      );
    } else {
      message = (
        <div className="redirect-msg">
          Please go to Item Management to view Item Level Scores
          {`${!_.isEmpty(additionalMessage) ? additionalMessage : '.'}`}
        </div>
      );
    }

    if (activeChart === 'contact' && _.includes(userApplications, 'CNTAPP')) {
      message = (
        <div className="redirect-msg">
          Please go to <Link to={'contact'}>Contact Management</Link> to view Contact Scores.
        </div>
      );
    } else if (activeChart === 'contact') {
      message = <div className="redirect-msg">Please go to Contact Management to view Contact Scores.</div>;
    }

    return message;
  }

  render() {
    let { summaryItems, actions, suvc, graphFilter, filterForGraphs, taxonomy, brands, userApplications } = this.props;

    let { Option } = Select;

    const stripFromFilter = strip => {
      let existingGraphFilter = JSON.parse(graphFilter);
      let strippedFilters = _.filter(existingGraphFilter, filterObj => {
        return strip.indexOf(filterObj.param) === -1;
      });
      return strippedFilters;
    };

    const filterGraph = (add, remove) => {
      let filterStr = null;
      let currentGraphFilter = parseJson(graphFilter);
      currentGraphFilter = stripFromFilter(remove);
      currentGraphFilter = appendToArrayWithSpread(currentGraphFilter, add);
      filterStr = stringifyJson(currentGraphFilter);
      actions.getDashboardSummary(
        { suvc: suvc, graphfilter: filterStr }
      );
    };

    const applyFilterForGraph = filter => {
      let updatedFilter = { ...filterForGraphs, ...filter };
      actions.changeFilterForGraph({ filter: { ...updatedFilter } });
    };

    const applyBrandFilterForGraph = e => {
      const brandFilter = syscoBrandValues[e.target.checked];
      applyFilterForGraph({
        sysco: brandFilter
      });
      const { add, remove } = getFilterGraphParameters('sysco', brandFilter, ['sysco']);
      filterGraph(add, remove);
    };

    const applyBrandNameFilterForGraph = newBrandName => {
      applyFilterForGraph({
        brand: newBrandName
      });
      const { add, remove } = getFilterGraphParameters('brand', newBrandName, ['brand']);
      filterGraph(add, remove);
    };

    const applyBcFilterForGraph = newBc => {
      applyFilterForGraph({
        bc: newBc,
        ig: null,
        ag: null
      });
      const { add, remove } = getFilterGraphParameters('bc', newBc, ['bc', 'ig', 'ag']);
      filterGraph(add, remove);
    };

    const applyIgFilterForGraph = newIg => {
      applyFilterForGraph({
        bc: filterForGraphs.bc,
        ig: newIg,
        ag: null
      });
      const { add, remove } = getFilterGraphParameters('ig', newIg, ['ig', 'ag']);
      filterGraph(add, remove);
    };

    const applyAgFilterForGraph = newAg => {
      applyFilterForGraph({
        bc: filterForGraphs.bc,
        ig: filterForGraphs.ig,
        ag: newAg
      });
      const { add, remove } = getFilterGraphParameters('ag', newAg, ['ag']);
      filterGraph(add, remove);
    };

    const emptyFilters = () => {
      applyFilterForGraph({ brand: null, bc: null, ig: null, ag: null, sysco: null });
      actions.getDashboardSummary({ suvc: suvc, graphfilter: null });
    };

    let businessCenters = Object.getOwnPropertyNames(taxonomy),
      itemGroups = [],
      attributeGroups = [];

    if (filterForGraphs.bc !== null) {
      itemGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc]);
      if (filterForGraphs.ig !== null) {
        attributeGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc][filterForGraphs.ig]);
      }
    }

    if (summaryItems && !_.isEmpty(summaryItems)) {
      return (
        <div className="extended-summary-wrapper">

          {this.renderRedirectMessage(userApplications)}

          <div className="score-filters-row">
            <Select defaultValue={filterForGraphs.bc} onChange={applyBcFilterForGraph}>
              <Option value={null} key="0">
                All Business Centers
              </Option>
              {businessCenters.map(bcOption => {
                return (
                  <Option value={bcOption} key={bcOption}>
                    {bcOption}
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue={filterForGraphs.ig}
              disabled={itemGroups.length === 0}
              onChange={applyIgFilterForGraph}
            >
              <Option value={null} key="0">
                All Item Groups
              </Option>
              {itemGroups.map(igOption => {
                return (
                  <Option value={igOption} key={igOption}>
                    {igOption}
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue={filterForGraphs.ag}
              disabled={attributeGroups.length === 0}
              onChange={applyAgFilterForGraph}
            >
              <Option value={null} key="0">
                All Attribute Groups
              </Option>
              {attributeGroups.map(agOption => {
                return (
                  <Option value={agOption} key={agOption}>
                    {agOption}
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue={filterForGraphs.brand}
              onChange={applyBrandNameFilterForGraph}
              className="graph-brand-filter"
            >
              <Option value={null} key="0">
                All Brands
              </Option>
              {brands.map(brandOption => {
                return (
                  <Option value={brandOption} key={brandOption}>
                    {brandOption}
                  </Option>
                );
              })}
            </Select>
            <Checkbox defaultChecked={filterForGraphs.sysco === '1'} onChange={applyBrandFilterForGraph}>
              Sysco Brand
            </Checkbox>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`filter-fanel${graphFilter ? ' active' : ''}`}
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              style={{ enableBackground: 'new -100 -100 700 700' }}
              fill="#abac92"
              viewBox="-100 -120 700 700"
              onClick={emptyFilters}
            >
              <g>
                <path d="M241.7,512H192V253.7L12.8,40.3V0h486.4v40.1L328.5,253.5v171.8L241.7,512z M226.1,477.9h1.5l66.8-66.8V241.5L460.3,34.1   H52.2l174,207.1L226.1,477.9z" />
              </g>
            </svg>
          </div>

          {this.summaryCards(summaryItems)}

          <BrandComplianceChart
            brandCompliance={summaryItems.brandCompliance}
            noEnrichment={summaryItems.noEnrichment}
            withEnrichment={summaryItems.withEnrichment} />

        </div>
      );
    } else {
      return (
        <div className="extended-summary-wrapper">
          <div className="loading-msg">Loading....</div>
        </div>
      );
    }
  }
}

export default ExtendedSummary;
