const initialState = {
  user: {
    fetching: false,
    data: {},
    error: null
  },
  scorecards: {
    scoresLoading: true,
    taxonomy: {},
    brands: [],
    scores: [
      {
        cardType: 'overall',
        title: 'OVERALL SCORE',
        score: 0
      },
      {
        cardType: 'attribution',
        title: 'PRODUCT ATTRIBUTION',
        score: 0
      },
      {
        cardType: 'image',
        title: 'PRODUCT IMAGES',
        score: 0
      },
      {
        cardType: 'fnb',
        title: 'FEATURES AND BENEFITS',
        score: 0
      },
      {
        cardType: "contact",
        title: 'CONTACTS',
        score: 0
      }
    ],
    activeChart: '',
    summaryItemsLoading: false,
    summaryItems: {},
    filterForGraphs: {
      brand: null,
      sysco: null,
      bc: null,
      ig: null,
      ag: null
    },
    graphfilter: null,
    banner: {
      message: "",
      color: "red"
    }
  }
};

export default initialState;
