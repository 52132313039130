import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import AppGrid from './AppGrid';
import DashboardSummary from './DashboardSummary';
import AppLoader from './AppLoader';
import { isMobile } from 'react-device-detect';

export class ApplicationDrawer extends React.Component {
  state = {
    shouldShowInfo: true,
    searchString: ''
  };

  showAppDetails = () => {
    const { shouldShowInfo } = this.state;
    this.setState({ shouldShowInfo: !shouldShowInfo });
  };

  onSearchChange = event => {
    this.setState({ searchString: event.target.value });
  };

  renderAppgrid = () => {
    const { shouldShowInfo, searchString } = this.state;
    const { filteredApps, userId, applications  } = this.props;
    const isNotItemSupplier = !( applications || []).includes('ITMAPP');

    if (this.props.suvc) {
      return (
        <div className="dashboard-summary-wrapper">
          <div className={`widgets-side${ isNotItemSupplier || isMobile ? ' mobile' : ''}`}>
            {filteredApps.length ? (
              <React.Fragment>
                <div className="dashboard-titles">Applications</div>
                <AppGrid shouldShowInfo={shouldShowInfo} filteredApps={filteredApps} searchString={searchString} userId={userId}/>
              </React.Fragment>
            ) : null}
          </div>
          <div className={`vertical-divider${ isNotItemSupplier || isMobile ? ' mobile' : ''}`} />
          <div className={`dashboard-side${ isNotItemSupplier || isMobile ? ' mobile' : ''}`}>
            <div className="dashboard-titles">Scores</div>
            <DashboardSummary suvc={this.props.suvc} />
          </div>
        </div>
      )
    } else {
      return (

         (filteredApps.length ? (
                  <AppGrid shouldShowInfo={shouldShowInfo} filteredApps={filteredApps} searchString={searchString} userId={userId}/>
                ) : null)

      )
    }
  }

  render() {

    return (
      <div className="app-grid">
        <div className="section-header" style={{ height: '0px', padding: '2px' }}></div>

        {this.props.isLoading ?
          <AppLoader show /> :
          this.renderAppgrid()}

      </div>
    );
  }
}

ApplicationDrawer.propTypes = {
  filteredApps: PropTypes.array,
  userId: PropTypes.object,
  t: PropTypes.func
};

function mapStateToProps(state) {
  return {
    suvc: _.get(state.user.data, 'vendor.vendorId', null),
    isLoading: state.scorecards.summaryItemsLoading
  };
}

export default connect(mapStateToProps)(withTranslation()(ApplicationDrawer));