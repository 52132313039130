import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const SCORES = createRequestTypes('SCORES');
const CHARTS = createRequestTypes('CHARTS');
const SUMMARY = createRequestTypes('SUMMARY');
const FILTERFORGRAPH = createRequestTypes('FILTERFORGRAPH');
const BANNERMESSAGE = createRequestTypes('BANNERMESSAGE');

export {
    USER,
    SCORES,
    CHARTS,
    SUMMARY,
    FILTERFORGRAPH,
    BANNERMESSAGE
};
